@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Merriweather:wght@300&display=swap');

body {
    background-image: linear-gradient(to right bottom, #00F260, #0575E6);
    background-attachment: fixed;
    color: #354538;
    font-family: sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right bottom, #00F260, #0575E6);
}

a {
    text-decoration: none;
    color: #354548;

}

a:hover {
    text-decoration: none;
    color: #000;
}

.app_container {
    padding-top: 50px;
}

.app_row {
    height: 86vh;
}




.app_main_content {
    background-color: #fff;
    border-radius: 20px;
    padding: 25px 15px;
    box-shadow: 0 0 10px black;
}

/* sidebar */
.sidebar {
    background-color: #fff;
    height: 100%;
    border-radius: 20px;
    padding: 25px 15px;
    box-shadow: 0 0 10px black;
    text-align: center;
}

.sidebar_avatar {
    height: 110px;
    width: 120px;
    object-fit: contain;
    border-radius: 30px;
}

.sidebar_icon {
    width: 25px;
    height: 25px;
    margin-top: -6px;
}

.sidebar_name {
    font-family: 'Kaushan Script';
    margin: 20px 0;
    font-size: 1.7rem;
}

.sidebar_name>span {
    color: #00F260;
}

.sidebar_item {
    margin-bottom: 6px;
    padding-top: 6px;
    height: 40px;
    font-size: 1.05rem;
    border-radius: 30px;
}

.sidebar_title {
    background-color: #f1f1f1;
    border-radius: 30px;
}

.sidebar_contact {
    background-color: #dbdddf;
    padding: 2px 0;
    margin: 20px -15px;

}

.sidebar_email {
    background-image: linear-gradient(to right bottom, #00F260, #0575E6);
    border-radius: 30px;
    color: #fff;
    margin: 15px 25px 0px;
    cursor: pointer;
    transition: all 0.3s linear;
}

.sidebar_email:hover {
    transform: scale(1.2);
}

.sidebar_github:hover,
.sidebar_resume:hover {
    background-color: #f1f1f2;
    color: #000;
}

@media(max-width:991px) {
    .app_main_content {
        margin: 30px 0;
    }
}

/* navbar */

.navbar_items {
    display: flex;
}

.navbar_active {
    font-size: 2rem;
    font-weight: 700;
    color: #354358;
    border-bottom: 4px solid #00F260;
}

.navbar_item {
    padding-right: 20px;
    text-decoration: none;
    color: #354358;
    font-size: 1.3rem;
    cursor: pointer;
}

.navbar_item:hover {
    background: -webkit-linear-gradient(#00F260, #0575E6);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

/* about */

.about {
    padding: 20px;
    margin: -35px;
}

.about_intro {
    font-size: 1.05rem;
    padding: 20px;
    margin: 10px 0;
}

.about_container {
    background-color: #dbdddf;
    padding: 25px 20px;
}

.about_heading {
    font-size: 25px;
    font-weight: 700;
    color: #354358;
    margin-left: 10px;
}

/* skill-card */
.skill-card {
    background-color: #fff;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
}

.skill-card_icon {
    width: 40px;
    height: 40px;
}

.skill-card_body {
    margin-left: 15px;

}

.skill-card_content {
    margin: 0;
    font-size: 0.9rem;
    color: #6e7e91;
}

/* resume */

.resume {
    padding-top: 20px;
}

.resume-card_heading,
.reume-language_heading {
    margin-left: 10px;
    font-size: 1.6rem;
    font-weight: 700;
    color: #354538;
}

.resume-card_body {
    margin-top: 10px;
}

.resume-card_title {
    font-weight: 700;
}

.resume-card_name {
    font-size: 16px;
}

.resume-card_details {
    font-size: 0.9rem;
    padding-top: 5px;
}

/* bar */
.bar {
    width: 90%;
    background-color: #f1f2f3;
    border-radius: 20px;
    margin-bottom: 12px;

}

.bar_icon {
    width: 23px;
    height: 23px;
    margin-right: 10px;
}

.bar_wrapper {
    background-image: linear-gradient(90deg, #00F260, #0575E6);
    border-radius: 20px;
    padding: 4px 14px;
    color: #fff;
    font-size: 0.9rem
}

/* projects */

.projects {
    height: 70vh;
    padding-top: 30px;
    overflow-y: auto;
}

.projectCard_wrapper {
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    height: 100%;
    min-height: 160px;
}

.projectCard_image {
    width: 100%;
    height: 80%;
    border-radius: 30px;
    border: 1px solid #00F260;
    transition: transform 0.3s linear;
}

.projectCard_image:hover {
    transform: scale(1.05);
}

.projectCard_icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
}

.projectCard_title {
    margin-top: 12px;
    font: size 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects_navbar {
    display: flex;
    margin-bottom: 5px;
}

.projects-navbar-active {
    color: #00F260;
}

.projects_navbar>div {
    margin: 0 5px;
    font-size: 1rem;
    cursor: pointer;
}

@media (max-width: 600px) {
    .app_main_content {
        margin-right: 10px;
        margin-left: 10px;
        padding: 34px 15px;
    }

    .navbar_active {
        font-size: 1.5rem;
    }

    .navbar_item {
        font-size: 1rem;
    }
}